import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React, { useEffect, useState } from 'react';
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { FaArrowAltCircleRight, FaSearch } from "react-icons/fa";
import DataTable from 'react-data-table-component';
import { MdDelete } from "react-icons/md";




const MyReferrals = () => {
  const [referrals, setReferral] = useState([]);

  const sessionId = useSelector((state) => state.session.sessionId);
  const user_id = useSelector((state) => state.session.setUserId);
  console.log("user_id",user_id);
  
  
  console.log(sessionId);
  
  
  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/customapi/Get.php";
      const payload = {
        // session_id: sessionId,
        refferalid : user_id.value   ,
        // select_fields: [
        //       "id",
        //       "refferid_c",
        //       "firstname_c",
        //       "lastname_c",
        //       "email_c",
        //       "phone_number_c",
        //     ],
      };
      console.log(payload,"payload");
      
      

      try {
        const response = await axios.get(apiUrl, {
          params: payload,
        });
        console.log("API Response:", response);

        
    const entryList = response.data.entry_list
        

        // Extracting name_value_list from each entry in entry_list and storing them in the products array
        const products = entryList.map((entry) => entry.name_value_list);
        
        console.log(products);
        setReferral(products);
        console.log(products[1].id);

        console.log(referrals, "data");
      } catch (error) {
        if (error.response) {
          console.error("An error occurred during get data:", error.response.data);
          console.error("Status code:", error.response.status);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error setting up request:", error.message);
        }
      }
    };

    if (sessionId) {
      fetchData();
    }
  }, [sessionId]);

  const deleteRefferal = async (id) => {
    const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/service/v4_1/rest.php";
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";
    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "Ref_Refferals",
      name_value_list: [
        {
          name: "id",
          value: id,
        },
        {
          name: "deleted",
          value: 1,
        },
      ],
    });

    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${encodeURIComponent(
      rest_data
    )}`;
    try {
      const response = await axios.post(fullUrl);

      console.log("deleted", response);
      setReferral((prevReferrals) => prevReferrals.filter((referral) => referral.id.value !== id));
      // setReferral((prevReferrals) => prevReferrals.filter((referral) => referral.id !== id));
    } catch {
      console.error("Error deleting product:");
    }
  };
  // useEffect(()=>{
  //   deleteRefferal
  // },[referrals])
  const columns = [
    // {
    //   name: "Referral ID",
    //   selector: (row) => row.refferid_c?.value,

    //   sortable: true,
    //   grow: 2
    // },
    {
      name: "First Name",
      selector: (row) => row.firstname_c?.value,

      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastname_c?.value,

      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email_c?.value,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone_number_c?.value,
      sortable: true,
    },

    {
      name: "Actions",
      width: "10%",
      cell: (row) => (
        <div className="py-4 flex flex-row gap-3">
          <MdDelete
            className="text-red-700 text-xl cursor-pointer"
            onClick={() => deleteRefferal(row.id.value)}
          />
        </div>
      ),
    },
  ];

  
    return (
      <>
      <DashboardNavbar />
      <div className=" flex justify-center min-h-screen p-4 m-5 w-full bg-gray-200">
      <div class="static overflow-x-auto pt-2 pb-2  w-[80%]  ">
              <div className="flex justify-between bg-blue-300 p-4 rounded items-center ">
                <div className="text-blue-950">Referrals </div>
                <Link to="/referfriend">
                  <button className="bg-blue-50 p-3 rounded flex items-center gap-2">
                    Add Referrals <FaArrowAltCircleRight />
                  </button>
                </Link>
              </div>
              <div className="-z-2">
                <DataTable
                  columns={columns}
                  data={referrals}
                  
                  selectableRows
                  pagination
                />
              </div>
            </div>
  </div>
        </>
      );
    }
        

export default MyReferrals;
