import React, { useState } from "react";
import { nextStep, updateCategoryDetails, prevStep } from "Features/jobForms/jobFormSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ReferralStep = () => {
  const category = useSelector((state) => state.form.category);
  const [form, setForm] = useState(category);
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateCategoryDetails(form));
    dispatch(nextStep());
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  return (
    <div className="w-full  flex flex-col justify-center">
      <div
        className="shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col justify-center border-blue-600 border"
        
      >
        <div><span className='max-md:block hidden text-center pb-2 font-bold '>Referral details</span></div>
        <div>
         
        </div>
        <div className="w-1/2 max-md:w-full flex flex-col m-auto">
        
          <div className="mb-4">
          <div className="  text-center pb-2 font-bold ">
          Thanks for applying the job
          </div>
            <label>
              <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange}  /> Do you have any referral?{" "}
            </label>
            {isChecked && (
              <div> 
                <form className=" rounded px-8 pt-6 pb-8 mb-4 flex flex-col justify-center ">
                <label> Enter the referral code</label>
                <input  type="text" className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"/>
                </form>
              </div>
            )

            }
          </div>

          <div className="flex flex-row justify-end">
            <Link to='/'>
            <button
              type="submit"
              className="bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded ml-5"
            >
              Next
            </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralStep;
