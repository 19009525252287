import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { prevStep, updateExperienceDetails,nextStep } from "Features/jobForms/jobFormSlice";
import { Button, TextField } from "@mui/material";

const step3 = () => {
  const dispatch = useDispatch();
  const experienceDetails = useSelector((state) => state.form.experienceDetails);
  const [form, setForm] = useState(experienceDetails);
  const [skills, setSkills] = useState([])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateExperienceDetails(form));
    dispatch(nextStep());
    console.log("form=>", form);
  };
  const addSkills = (event) => {
    if (inputValue.trim() && !categories.includes(inputValue)) {
      setCategories((prevCategories) => [...prevCategories, inputValue]);
      setInputValue(""); // Clear the input field after adding
    } else {
      alert("Category already exists or input is empty.");
    }
  };
  const removeSkill = (index) => {
    setSkills(skills.filter((_, i) => i !== index));
  };
  return (
    <div className="w-full  flex flex-col justify-center">
      <form onSubmit={handleSubmit} className=" shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col justify-center border-blue-600 border">
      <div><span className='max-md:block hidden text-center pb-2 font-bold '>Experience details</span></div>
        <div className="w-1/2 flex max-md:w-full flex-col m-auto ">
        <div className="mb-4 ">
          <label>Do You Have Experience <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
            <select
              
              id="experience"
              onChange={handleChange}
             
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            >
              <option value="" disabled>
                Select your gender
              </option>
              <option value="yes">yes</option>
              <option value="no">No</option>
            </select>
          </div>
        <div className="mb-4"> 
        <label>Years of Experience <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
          <input
            type="text"
            name="experience"
            value={form.experience}
            onChange={handleChange}
            
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
          />
        </div>
        <div className="mb-4">
        <label>Current/Past Position <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
          <input
            type="text"
            name="currentPosition"
            value={form.currentPosition}
            onChange={handleChange}
           
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
          />
        </div>
        <div className="mb-4">
        <label>Current/Past Salary <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
          <input
            type="text"
            name="currentCTC"
            value={form.currentCTC}
            onChange={handleChange}
            
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
          />
        </div>
        <div className="mb-4">
        <label>Country <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
          <input
            type="text"
            name="country"
            value={form.country}
            onChange={handleChange}
            placeholder=""
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
          />
        </div>
        <div className="mb-4">
        <label>Work Permit Number <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
          <input
            type="text"
            name="workPermitNumber"
            value={form.workPermitNumber}
            onChange={handleChange}
            placeholder=""
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
          />
        </div>
        <div className="mb-4">
        <label>Work Permit file <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
          <input
            type="file"
            name="workPermitFile"
            value={form.workPermitFile}
            onChange={handleChange}
            placeholder=""
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
          />
        </div>
       
      
        <div className="mb-4 ">
        <label>Cover Letter <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
            <textarea
              name="coverLetter"
              value={form.coverLetter}
              onChange={handleChange}
             
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            />
          </div>
          <div className="mb-4 ">
          <label>Cover Letter <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
            <input
              type="file"
              name="resume"
              onChange={handleChange}
              placeholder=""
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            />
          </div>
        <div className="mb-4">
        <label>Skills <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
          <input
            type="text"
            name="skills"
            value={form.skills}
            onChange={handleChange}
            placeholder=""
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            onKeyDown={addSkills}
          />
          <div className="flex">
            {skills?.map((skill,index) => (
              <div key={index} className="flex items-center border m-2 p-2 rounded">
              <div
                className="mr-2 p-1 px-2 bg-red-500 text-white rounded cursor-pointer"
                onClick={() => removeSkill(index)}
              >
                X
              </div>
              <div>{skill}</div>
            </div>
            ))}
          
          
         
          </div>
        </div>
       
        
        <div className="flex flex-row justify-end">
        <button
          className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
          onClick={() => dispatch(prevStep())}
        >
          Previous
        </button>

        <button type="submit" className="bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded ml-5">
          Next
        </button>
        </div>
        </div>
      </form>
    </div>
  );
};

export default step3;
