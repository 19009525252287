import React, { useState } from "react";
import JobCard1 from "./jobcard1";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import './style.css';

const jobs = [
  {
    id: 1,
    title: "Nurse",
    company: "General Hospital",
    location: "Boston",
    category: "Healthcare",
    jobType: "Onsite",
    requiredSkill: "Patient care, Medical knowledge",
    closeDate: "2024-08-01",
  },
  {
    id: 2,
    title: "Driver",
    company: "FedEx",
    location: "New York",
    category: "Transportation",
    jobType: "Onsite",
    requiredSkill: "Driving, Time management",
    closeDate: "2024-08-05",
  },
  {
    id: 3,
    title: "Salesman",
    company: "Best Buy",
    location: "Chicago",
    category: "Sales",
    jobType: "Onsite",
    requiredSkill: "Customer service, Sales techniques",
    closeDate: "2024-08-10",
  },
  {
    id: 4,
    title: "Welder",
    company: "Steel Works",
    location: "Houston",
    category: "Manufacturing",
    jobType: "Onsite",
    requiredSkill: "Welding, Metal fabrication",
    closeDate: "2024-08-15",
  },
  {
    id: 5,
    title: "Carpenter",
    company: "Woodcraft Builders",
    location: "Denver",
    category: "Construction",
    jobType: "Onsite",
    requiredSkill: "Carpentry, Blueprint reading",
    closeDate: "2024-08-20",
  },
  {
    id: 6,
    title: "Front Desk Officer",
    company: "Hilton Hotel",
    location: "Miami",
    category: "Hospitality",
    jobType: "Onsite",
    requiredSkill: "Customer service, Communication",
    closeDate: "2024-08-25",
  }
];


const JobLists1 = () => {
  const [expandedId, setExpandedId] = useState(null);

  const handleExpand = (id) => {
    setExpandedId(id === expandedId ? null : id);
  };

  return (
    <>
    <DashboardNavbar />
    <div className="flex flex-col items-center justify-center min-h-screen joblist">
  <h1 className="w-full text-2xl font-bold text-center text-white my-6">Job Listings</h1>
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full px-4">
    {jobs.map((job) => (
      <div key={job.id} className="flex justify-center">
        <JobCard1
          job={job}
          expandHandler={() => handleExpand(job.id)}
          isExpanded={expandedId === job.id}
        />
      </div>
    ))}
  </div>
</div>
</>
  );
};

export default JobLists1;
