import React, { useState } from "react";
import { nextStep, updateCategoryDetails, prevStep } from "Features/jobForms/jobFormSlice";
import { useDispatch, useSelector } from "react-redux";

const categoryStep = () => {
  const category = useSelector((state) => state.form.category);
  const [form, setForm] = useState(category);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateCategoryDetails(form));
    dispatch(nextStep());
  };
  return (
    <div className="w-full  flex flex-col justify-center">
     
      <form className="shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col justify-center border-blue-600 border" onSubmit={handleSubmit}>
      <div><span className='max-md:block hidden text-center pb-2 font-bold '>Category details</span></div>
        <div className="w-1/2 max-md:w-full flex flex-col m-auto">
        <div className="mb-4">
          <select
            value={form.category}
            name="category"
            id="category"
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
          >
            <option value="" >
              Select your category
            </option>
            <option value="Painter">Painter</option>
            <option value="Welder">Welder</option>
            <option value="Driver">Driver</option>
          </select>
        </div>

        <div className="flex flex-row justify-end">
          
          <button
            type="submit"
            className="bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded ml-5"
          >
            Next
          </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default categoryStep;
