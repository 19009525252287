const initialState = {
    sessionId : null,
    setUserId :null,
}

const sessionReducer = (state = initialState,action )=>{
    switch (action.type) {
        case 'SET_SESSION_ID':
            return {
                ...state,
                sessionId: action.payload,
            };
        case 'SET_USER_ID':
            return {
                ...state,
                setUserId: action.payload,
            };
        case 'CLEAR_SESSION_ID':
            return {
                ...state,
                sessionId: null,
            };
        default:
            return state;
    }
}
export default sessionReducer;