/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
//import MDButton from "components/MDButton";
import { Button } from "@mui/material";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in2-basic.webp";
import logoImage from "assets/images/moona-logo.png";
import { useState } from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom';

function SignUp() {
  const [signup , setSignup] = useState({
    user_name: "",
    first_name: "",
    last_name:"",
    email_c:"",
    user_hash:"",
    cnfrm_c:"",


})

const navigate = useNavigate()
const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const restValue = queryParams.get('referral_id'); 
console.log(restValue);

const handleChange = (e) => {
  const { name, value } = e.target;
  setSignup((prevState) => ({
    ...prevState,
    [name]: value,
  }));
};
    const handleLogin = async (e) => {
      e.preventDefault();
  
      const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/customapi/Create.php";
      
    const payload = {
     
      // referral_id: restValue ? restValue : "",
      fields:  [
        { name: "user_name", value: signup.user_name },
        {name: "user_hash", value : signup.user_hash },
        {name : "cnfrm_c", value :signup.cnfrm_c},
        { name: "first_name", value: signup.first_name },
        { name: "last_name", value: signup.last_name },
        { name: "email_c", value: signup.email_c },
        { name: "phone_mobile", value: signup.phone_mobile },
        
        {name  : "employee_status" , value : "Active"},
        {name : "status", value : "active"},
        {name : "refer", value :restValue ? restValue : "",}
      ]
    };
    console.log("payload",payload);
    
   
    
      
      try {
        const response = await axios.post(apiUrl,payload
         
        )

  
        if (response.status === 200) {
          console.log("Login successful:", response.data);
          console.log("payload",payload);
          navigate("/dashboard");
          
          
          
         
         
          
          // Handle successful login, e.g., store token, redirect, etc.
        } else {
          console.error("Login failed:", response.data);
        }
      } catch (error) {
        if (error.response) {
          console.error("An error occurred during login:", error.response.data);
          console.error("Status code:", error.response.status);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error setting up request:", error.message);
        }
      }
  };
  
  return (
    <CoverLayout image={bgImage}>
      <Card sx={{ width: 'auto',maxWidth:"700px" }}>
        <MDBox
          
        >
          <MDTypography
        variant="h4" fontWeight="medium" color="white" mt={1}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
       >
      <img src={logoImage} alt="Moona" style={{ width: '85px', height: '75px' }} />
       </MDTypography>
          
        </MDBox>
        <form onSubmit={handleLogin}>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox  role="form">
            <MDBox mb={2}>
              <MDInput type="text" label="Username" onChange={handleChange} value={signup.user_name} name='user_name' id="user_name" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="text" label="First Name" onChange={handleChange} value={signup.first_name} name='first_name' id="first_name" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="text" label="Last Name" onChange={handleChange} value={signup.last_name} name='last_name' id="last_name" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="email" label="Email" onChange={handleChange} value={signup.email_c} name='email_c' id="email_c"  variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="number" label="Mobile No:" onChange={handleChange} value={signup.phone_mobile} name='phone_mobile' id="phone_mobile"  variant="standard" fullWidth  />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" onChange={handleChange} value={signup.user_hash} name='user_hash' id="user_hash"  variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Confirm Password" onChange={handleChange} value={signup.cnfrm_c} name='cnfrm_c' id="cnfrm_c"  variant="standard" fullWidth />
            </MDBox>
            
               
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox checked />
              <MDTypography
                variant="button"
                fontWeight="regular"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 , color: '#012034'}}
              >
                &nbsp;&nbsp;I agree the&nbsp;<MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                sx={{ color: '#012034' }}
                textGradient
              >
                Terms and Conditions
              </MDTypography>
              </MDTypography>
              
            </MDBox>
            <MDBox mt={4} mb={1}>
            <Button
            type="submit"
                variant="gradient"
                sx={{
                  backgroundColor: '#012034',
                  color: '#ffffff',
                  width: '100%',
                  padding: '6px 8px', // Increase padding for a larger button
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: '#012034', // Slightly lighter shade for hover effect
                  },
                  '&:active': {
                    backgroundColor: '#012034', // Maintain the same color on click
                  },
                }}
              >
               Register
              </Button>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button"  sx={{ color: '#012034' }}>
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  sx={{ color: '#012034' }}
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        </form>
      </Card>
    </CoverLayout>
  );
}

export default SignUp;
