// EditProfile.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const EditProfile = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({
    firstName: "Alec",
    lastName: "Thompson",
    dob: "1992-08-13",
    email: "alecthompson@mail.com",
    mobile: "+91-9835772315",
    referredBy: "Anand",
    address: "7G-RajaNagar, Othakkadai",
    city: "Trichy",
    country: "India",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleSave = () => {
    // Save profile logic here
    navigate("/profile");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={3} p={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              value={profile.firstName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              value={profile.lastName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Date of Birth"
              name="dob"
              value={profile.dob}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
        <TextField
            fullWidth
            label="Email"
            name="email"
            value={profile.email}
            onChange={handleChange}
         />
       </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Mobile"
              name="mobile"
              value={profile.mobile}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Referred By"
              name="referredBy"
              value={profile.referredBy}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Address"
              name="address"
              value={profile.address}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="City"
              name="city"
              value={profile.city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Country"
              name="country"
              value={profile.country}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                sx={{ color: '#FFFFFF', mr: 2 }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                style={{ color: 'white', backgroundColor: '#f44336' }}
                onClick={handleSave}
                
              >
                Cancel
              </Button>
            </MDBox>
          </Grid>
          
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default EditProfile;
