import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React from 'react';
import './style.css'

const jobApplications = [
  { jobTitle: 'Software Engineer', company: 'Tech Corp', location: 'New York, NY', status: 'Applied', appliedDate: '2023-07-01' },
  { jobTitle: 'Product Manager', company: 'Innovate Ltd.', location: 'San Francisco, CA', status: 'Interview', appliedDate: '2023-07-03' },
  { jobTitle: 'Data Scientist', company: 'DataWorks', location: 'Boston, MA', status: 'Offer', appliedDate: '2023-07-05' },
  { jobTitle: 'Software Engineer', company: 'Tech Corp', location: 'New York, NY', status: 'Applied', appliedDate: '2023-07-01' },
  { jobTitle: 'Product Manager', company: 'Innovate Ltd.', location: 'San Francisco, CA', status: 'Interview', appliedDate: '2023-07-03' },
  { jobTitle: 'Data Scientist', company: 'DataWorks', location: 'Boston, MA', status: 'Offer', appliedDate: '2023-07-05' },
  { jobTitle: 'Software Engineer', company: 'Tech Corp', location: 'New York, NY', status: 'Applied', appliedDate: '2023-07-01' },
  { jobTitle: 'Product Manager', company: 'Innovate Ltd.', location: 'San Francisco, CA', status: 'Interview', appliedDate: '2023-07-03' },
  { jobTitle: 'Data Scientist', company: 'DataWorks', location: 'Boston, MA', status: 'Offer', appliedDate: '2023-07-05' },
];

const Applications1 = () => {
  return (
    <>
    <DashboardNavbar />
    <div className="flex justify-center p-4 min-h-screen m-5 myreferral">
      <div className="overflow-x-auto w-full md:w-4/5 lg:w-4/5 " >
    <h1 className="w-full text-2xl font-bold text-center text-white py-5 ">My Applications 2</h1>

        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="px-4 py-2 text-left border-b-2 border-gray-300">S.no</th>
              <th className="px-4 py-2 text-left border-b-2 border-gray-300">Job Title</th>
              <th className="px-4 py-2 text-left border-b-2 border-gray-300">Company</th>
              <th className="px-4 py-2 text-left border-b-2 border-gray-300">Location</th>
              <th className="px-4 py-2 text-left border-b-2 border-gray-300">Application Status</th>
              <th className="px-4 py-2 text-left border-b-2 border-gray-300">Applied Date</th>
            </tr>
          </thead>
          <tbody>
            {jobApplications.map((job, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="px-4 py-2 border-b border-gray-200 text-center">{index + 1}</td>
                <td className="px-4 py-2 border-b border-gray-200">{job.jobTitle}</td>
                <td className="px-4 py-2 border-b border-gray-200">{job.company}</td>
                <td className="px-4 py-2 border-b border-gray-200">{job.location}</td>
                <td className="px-4 py-2 border-b border-gray-200">{job.status}</td>
                <td className="px-4 py-2 border-b border-gray-200">{job.appliedDate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </>
  );
}


export default Applications1;
