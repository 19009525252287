import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nextStep, updatePersonalInfo, prevStep } from "Features/jobForms/jobFormSlice";
import { TextField } from "@mui/material";

const step1 = () => {
  const dispatch = useDispatch();
  const personalDetails = useSelector((state) => state.form.personalInfo);
  const [form, setForm] = useState(personalDetails);
  const [LanguageKnown, setLanguageKnown] = useState([])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updatePersonalInfo(form));
    dispatch(nextStep());
  };
  const addLanguage = (e) => {
    if (e.key === 'Enter' && form.LanguageKnown.trim() !== '') {
      e.preventDefault();
      setLanguageKnown((prevLanguages) => [...prevLanguages, form.LanguageKnown.trim()]);
      setForm({ LanguageKnown: '' });
    }
  };

  const removeLanguage = (index) => {
    setLanguageKnown((prevLanguages) => prevLanguages.filter((_, i) => i !== index));
  };
  return (
    <div className="">
      <form
        onSubmit={handleSubmit}
        className=" shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col justify-center border-blue-600 border"
      >
        <div><span className='max-md:block hidden text-center pb-2 font-bold '>Personal details</span></div>
        <div className="flex max-md:flex-col align-top pt-5 px-5 ">
        <div className="flex flex-col  w-[70%] max-md:w-full max-md:justify-center justify-between max-md:pr-0 pr-5">
          <div className="mb-4 mx-2 w-full md:w-auto">
          <label>First Name <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
            <input
              type="text"
              name="firstName"
              value={form.firstName}
              onChange={handleChange}
              placeholder=""
              
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            />
          </div>
          <div className="mb-4 mx-2 w-full md:w-auto">
          <label>Last Name <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
            <input
              type="text"
              name="lastName"
              value={form.lastName}
              onChange={handleChange}
              placeholder=""
              
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            />
          </div>
          <div className="mb-4 mx-2 w-full md:w-auto">
          <label>Email <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
            <input
              type="email"
              name="email"
              value={form.email}
              onChange={handleChange}
              placeholder=""
              
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            />
          </div>
          <div className="mb-4 mx-2 w-full md:w-auto">
          <label>Date Of Birth <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
            <input
              type="date"
              name="dob"
              value={form.dob}
              onChange={handleChange}
              placeholder="Date Of Birth"
              
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            />
          </div>
          <div className="mb-4 mx-2 w-full md:w-auto">
          <label>Phone Number <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
            <input
              type="number"
              name="phoneNumber"
              value={form.phoneNumber}
              onChange={handleChange}
              placeholder=""
              
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            />
          </div>
          <div className="mb-4 mx-2 w-full md:w-auto">
          <label>Alternative Phone Number</label>
            <input
              type="number"
              name="alternativePhoneNumber"
              value={form.alternativePhoneNumber}
              onChange={handleChange}
              
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            />
          </div>
          <div className="mb-4 mx-2 w-full md:w-auto">
          <label>Gender <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
            <select
              value={form.gender}
              name="gender"
              id="gender"
              onChange={handleChange}
             
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            >
              <option value="" disabled>
                Select your gender
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="mb-4 mx-2 w-full md:w-auto">
          <label>Nationality <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
            <input
              type="text"
              name="nationality"
              value={form.nationality}
              onChange={handleChange}
              placeholder=""
              
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            />
          </div>
         
        </div>
        
        <div className="flex flex-col w-[70%] max-md:w-full max-md:pl-0  m-auto max-md:justify-center pl-5 justify-between">
        <div className="mb-4 mx-2 w-full md:w-auto">
        <label>Disability<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
            <select
              value={form.disability}
              name="disability"
              id="disability"
              
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            >
              
              <option value="male" selected>no</option>
              <option value="female">yesy</option>
            </select>
          </div>
        
          
          <div className="mb-4 mx-2 w-full md:w-auto">
          <label>Street<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
            <input
              type="text"
              name="street"
              value={form.street}
              onChange={handleChange}
              placeholder=""
              
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            />
          </div>
          <div className="mb-4 mx-2 w-full md:w-auto">
          <label>City<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
            <input
              type="text"
              name="city"
              value={form.city}
              onChange={handleChange}
              placeholder=""
              
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            />
          </div>
          <div className="mb-4 mx-2 w-full md:w-auto">
          <label>Country<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
            <input
              type="text"
              name="country"
              value={form.country}
              onChange={handleChange}
              
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            />
          </div>
          <div className="mb-4 mx-2 w-full md:w-auto">
          <label>Zipcode<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
            <input
              type="number"
              name="zipcode"
              value={form.zipcode}
              onChange={handleChange}
              
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            />
          </div>
          <div className="mb-4 mx-2 w-full md:w-auto">
          <label>AadharCard<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
            <input
              type="file"
              name="aadharCard"
              value={form.aadharCard}
              onChange={handleChange}
              
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            />
          </div>
          <div className="mb-4 mx-2 w-full md:w-auto">
          <label>Passport<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
            <input
              type="file"
              name="passpord"
              value={form.passpord}
              onChange={handleChange}
              
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            />
          </div>
          <div className="mb-4 mx-2 w-full md:w-auto">
          <label>Pan Card</label>
            <input
              type="file"
              name="panCard"
              value={form.panCard}
              onChange={handleChange}
              
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            />
          </div>
         
         
          <div className="mb-4">
          <label>Language Known<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
          <input
            type="text"
            name="LanguageKnown"
            value={form.LanguageKnown}
            onChange={handleChange}
           
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            onKeyDown={addLanguage}
          />
          <div className="flex">
            {LanguageKnown?.map((LanguageKnown,index) => (
              <div key={index} className="flex items-center border m-2 p-2 rounded">
              <div
                className="mr-2 p-1 px-2 bg-red-500 text-white rounded cursor-pointer"
                onClick={() => removeLanguage(index)}
              >
                X
              </div>
              <div>{LanguageKnown}</div>
            </div>
            ))}
          
          
         
          </div>
        </div>
        </div>
        </div>

        <div className="flex flex-row justify-end">
        <button
          className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
          onClick={() => dispatch(prevStep())}
        >
          Previous
        </button>
          <button
            type="submit"
            className="bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded ml-5"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default step1;
