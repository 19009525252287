/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import MenuItem from '@mui/material/MenuItem';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";




// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenuBar, setOpenMenuBar] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const [mobileOpen, setMobileOpen] = useState(false);

const handleDrawerToggle = () => {
  setMobileOpen(!mobileOpen);
};

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleOpenMenuBar = (event) => setOpenMenuBar(event.currentTarget);
  const handleCloseMenuBar = () => setOpenMenuBar(false);

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Render the notifications menu
  // const renderMenu = () => (
  //   <Menu
  //     anchorEl={openMenu}
  //     anchorReference={null}
  //     anchorOrigin={{
  //       vertical: "bottom",
  //       horizontal: "left",
  //     }}
  //     open={Boolean(openMenu)}
  //     onClose={handleCloseMenu}
  //     sx={{ mt: 2 }}
  //   >
  //     <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
  //     <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
  //     <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
  //   </Menu>
  // );

  const renderAccountMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
<Link to="/myApplication" style={{ textDecoration: "none" }} onClick={handleCloseMenu}>
  <NotificationItem icon={<Icon>badge</Icon>} title="My Applications" />
</Link>
<Link to="/myReferral" style={{ textDecoration: "none" }} onClick={handleCloseMenu}>
  <NotificationItem icon={<Icon>group</Icon>} title="My Referrals" />
</Link>
<Link to="/profile" style={{ textDecoration: 'none' }}>
  <NotificationItem icon={<Icon>person</Icon>} title="Profile" />
</Link>
  <NotificationItem icon={<Icon>logout</Icon>} title="Logout" />
<Link to="/authentication/sign-in" style={{ textDecoration: 'none' }}>
  <NotificationItem icon={<Icon>login</Icon>} title="Sign In" />
</Link>
<Link to="/referfriend" style={{ textDecoration: 'none' }}>
  <NotificationItem icon={<Icon>group</Icon>} title="ReferFriend" />
</Link>
<Link to="/" style={{ textDecoration: 'none' }}>
  <NotificationItem icon={<Icon>person_add</Icon>} title="Sign Up" />
</Link>
<Link to="/joblist2" style={{ textDecoration: 'none' }}>
  <NotificationItem icon={<Icon>work</Icon>} title="Job Lists-2" />
</Link>
<Link to="/myReferral2" style={{ textDecoration: 'none' }}>
  <NotificationItem icon={<Icon>group</Icon>} title="My Referrals-2" />
</Link>
<Link to="/myReferral3" style={{ textDecoration: 'none' }}>
  <NotificationItem icon={<Icon>group</Icon>} title="My Referrals-3" />
</Link>
<Link to="/myApplication2" style={{ textDecoration: 'none' }}>
  <NotificationItem icon={<Icon>badge</Icon>} title="My Applications-2" />
</Link>
<Link to="/myApplication3" style={{ textDecoration: 'none' }}>
  <NotificationItem icon={<Icon>badge</Icon>} title="My Applications-3" />
</Link>
      {/* <Link to="/authentication/sign-in" style={{ textDecoration: 'none' }}>
  <NotificationItem icon={<Icon>login</Icon>} title="Sign In" />
</Link>
<Link to="/authentication/sign-up" style={{ textDecoration: 'none' }}>
  <NotificationItem icon={<Icon>person_add</Icon>} title="Sign Up" />
</Link> */}
    </Menu>
  );
  const renderMenuBar = () => (
    <Menu
      anchorEl={openMenuBar}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenuBar)}
      onClose={handleCloseMenuBar}
      sx={{ mt: 2 }}
    >
       {/* <Link to="/applications" style={{ textDecoration: "none" }}>
      </Link> */}
      {/* <NotificationItem icon={<Icon>badge</Icon>} title="My Applications" />
      <NotificationItem icon={<Icon>group</Icon>} title="My Referrals" />
      <NotificationItem icon={<Icon>person</Icon>} title="Profile" />
      <NotificationItem icon={<Icon>logout</Icon>} title="Logout" /> */}
      <Link to="/referfriend" style={{ textDecoration: "none" }} onClick={handleCloseMenu}>
        <MDButton variant="gradient" sx={{margin:'20px', marginBottom:'0px'}} color="info" >
               Refer A Friend
        </MDButton>
      </Link><br />
      <Link to="/myReferral" style={{ textDecoration: "none" }} onClick={handleCloseMenu}>
        <MDButton variant="gradient" sx={{margin:'20px', marginBottom:'0px'}} color="info" >
                My Referrals
        </MDButton>
      </Link><br />
      <Link to="/jobApplication" style={{ textDecoration: "none" }} onClick={handleCloseMenu}>
        <MDButton variant="gradient" sx={{ margin:'20px'}} color="info" >
                Apply Now
              </MDButton>
              </Link>
      {/* <Link to="/authentication/sign-in" style={{ textDecoration: 'none' }}>
  <NotificationItem icon={<Icon>login</Icon>} title="Sign In" />
</Link>
<Link to="/authentication/sign-up" style={{ textDecoration: 'none' }}>
  <NotificationItem icon={<Icon>person_add</Icon>} title="Sign Up" />
</Link> */}
    </Menu>
  );


  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => ({...navbarRow(theme, { isMini }), })}>
          <Breadcrumbs icon="home"
           title={route[route.length - 1]} route={route} 
           light={light} sx={{ fontSize: '36px' }} />
       
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            
            {!isMediumScreen && (
            <>
            <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox>
            <Link to="/referfriend" style={{ textDecoration: "none" }} onClick={handleCloseMenu}>
              <MDButton variant="gradient" sx={{marginLeft:'20px'}} color="info" >
                Refer a Friend
              </MDButton>
            </Link>
            <Link to="/myReferral" style={{ textDecoration: "none" }} onClick={handleCloseMenu}>
                <MDButton variant="gradient" sx={{marginLeft:'20px'}} color="info" >
                  My Referrals
                </MDButton>
            </Link>
            <Link to="/jobApplication" style={{ textDecoration: "none" }} onClick={handleCloseMenu}>
              <MDButton variant="gradient" sx={{marginLeft:'20px'}} color="info" >
                  Apply Now
                </MDButton>
                </Link>
            </>
            )}
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                size="large"
                disableRipple
                color="inherit"
                sx={{ ...navbarIconButton, fontSize:'50px',
                  [theme.breakpoints.down('md')]: {
                    fontSize: '32px',
                    paddingRight:'0px',
                  },
                }}
                className="text-[30px]"
                aria-controls="notification-menu"
                aria-haspopup="true" 
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon  sx={iconsStyle}>account_circle</Icon>
              </IconButton> 
              {renderAccountMenu()}
              {isMediumScreen && (
                <>
              <IconButton
                size="large"
                disableRipple
                color="inherit"
                sx={{...navbarMobileMenu, fontSize:'32px' }}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenuBar}
              >
                <Icon sx={iconsStyle} fontSize="large">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              {renderMenuBar()}
              </>
              )}
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton> */}
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon sx={iconsStyle}>notifications</Icon>
              </IconButton> 
              {renderMenu()} */}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
