import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { prevStep, updateEducationDetails,nextStep } from "Features/jobForms/jobFormSlice";
import { Button, TextField } from "@mui/material";

const step2 = () => {
  const dispatch = useDispatch();
  const educationDetails = useSelector((state) => state.form.educationDetails);
  const [form, setForm] = useState(educationDetails);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateEducationDetails(form));
    dispatch(nextStep());
  };
  return (
    <div className="w-full  flex flex-col justify-center ">
      <form onSubmit={handleSubmit} className=" shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col justify-center border-blue-600 border">
      <div><span className='max-md:block hidden text-center pb-2 font-bold '>Educational details</span></div>
        <div className="w-1/2 max-md:w-full flex flex-col m-auto">
        <div className="mb-4 ">
        <label>Qualification<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
            <select
              value={form.qualification}
              name="qualification"
              id="qualification"
              
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
            >
              
              <option value="ssls" >SSLC</option>
              <option value="hsc">HSC</option>
              <option value="diploma">Diploma</option>
              <option value="bachelorDegree">Bachelor Degree</option>
              <option value="masterDegree">Master Degree</option>
              <option value="others">Other</option>
            </select>
          </div>
        <div className="mb-4">
        <label>SSLC<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
          <input
            type="file"
            name="ssls"
            value={form.ssls}
            onChange={handleChange}
           
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
          />
        </div>
        <div className="mb-4">
        <label>HSC<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
          <input
            type="file"
            name="hsc"
            value={form.hsc}
            onChange={handleChange}
           
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
          />
        </div>
        <div className="mb-4">
        <label>Diploma<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
          <input
            type="file"
            name="diploma"
            value={form.diploma}
            onChange={handleChange}
           
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
          />
        </div>
        <div className="mb-4">
        <label>Bachelor Degree<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
          <input
            type="file"
            name="bachelorDegree"
            value={form.bachelorDegree}
            onChange={handleChange}
           
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
          />
        </div>
        <div className="mb-4">
        <label>Master Degree<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
          <input
            type="file"
            name="masterDegree"
            value={form.masterDegree}
            onChange={handleChange}
           
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
          />
        </div>
        <div className="mb-4">
        <label>Course Completion Certificate<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
          <input
            type="file"
            name="courseCompletion"
            value={form.courseCompletion}
            onChange={handleChange}
           
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600" 
          />
        </div>
        <div className="mb-4">
        <label>University<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
          <input
            type="number"
            name="university"
            value={form.university}
            onChange={handleChange}
            placeholder=""
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
          />
        </div>
        <div className="mb-4">
        <label>Graduation Year<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></label>
          <input
            type="number"
            name="graduationYear"
            value={form.graduationYear}
            onChange={handleChange}
            placeholder=""
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-600"
          />
        </div>
       
       
       
        <div className="flex flex-row justify-end">
        <button
          className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
          onClick={() => dispatch(prevStep())}
        >
          Previous
        </button>

        <button type="submit" className="bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded ml-5">
          Next
        </button>
        </div>
        </div>
      </form>
    </div>
  );
};

export default step2;
