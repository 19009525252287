import { Category, Language } from "@mui/icons-material";
import { createSlice } from "@reduxjs/toolkit";
import { position } from "stylis";

const initialState = {
  step: 1,
  category:{
    Category:"",
  },
  personalInfo: {
    firstName: "",
    lastName: "",
    dob:"",
    email:"",
    phoneNumber:"",
    alternativeNumber:"",
    gender:"",
    nationality:"",
    disability:"no",
    street:"",
    city:"",
    country:"",
    zipcode:"",
    LanguageKnown:[],
    aadharCard:"",
    passpord:"",
    panCard:"",
    
  },
  educationDetails: {
    qualification:"",
    ssls:"",
    hsc:"",
    diploma:"",
    bachelorDegree:"",
    masterDegree:"",
    
    courseCompletion: "",
    university:"",
    graduationYear:"",
  },
  experienceDetails: {
    experience: "",
    currentPosition: "",
    currentCTC:"",
    country:"",
    workPermitNumber:"",
    workPermitFile:"",
    skills:[],
    coverLetter:"",
    resume:"",
  },
  referralDetails:{
    referralCode:"",
  }
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    nextStep: (state) => {
      state.step += 1;
    },
    prevStep: (state) => {
      state.step -= 1;
    },
    updatePersonalInfo: (state, action) => {
      state.personalInfo = action.payload;
    },
    updateEducationDetails: (state, action) => {
      state.educationDetails = action.payload;
    },
    updateExperienceDetails: (state, action) => {
      state.experienceDetails = action.payload;
    },
    updateCategoryDetails: (state, action) => {
      state.category = action.payload;
    },
  },
});


export const { nextStep, prevStep, updatePersonalInfo, updateEducationDetails,updateExperienceDetails,updateCategoryDetails } = formSlice.actions;
export default formSlice.reducer;
