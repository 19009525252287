import React, { useState } from "react";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import './style.css';


const JobCard1 = ({ job, expandHandler, isExpanded }) => {
  
  return (
    
    <div className="w-full shadow-md rounded-lg p-6 m-4 transition-all bg-white duration-300" >
      {/* <div className="cursor-pointer" onClick={expandHandler}> */}
      <div >
        <div className="space-y-2">
          <h3 className=" font-bold">{job.title}</h3>
          <p><strong>Company:</strong> {job.company}</p>
          <p><strong>Location:</strong> {job.location}</p>
          <p><strong>Type:</strong> {job.jobType}</p>
          <p><strong>Category:</strong> {job.category}</p>
          <p><strong>Skills:</strong> {job.requiredSkill}</p>
          {/* <p><strong>Open Date:</strong> {job.openDate}</p> */}
          <p><strong>Close Date:</strong> {job.closeDate}</p>
          <div className="mt-2">
            <Link to="./jobApplication" style={{ textDecoration: "none" }}>
            <MDButton variant="gradient" fullWidth color="info" >
                Apply Now
              </MDButton>
              </Link>
          </div>
        </div>
      </div>
      {/* {isExpanded && (
        <div className="space-y-2 mt-2">
          <p><strong>Position:</strong> {job.position}</p>
          <p><strong>No of Openings:</strong> {job.noOfOpenings}</p>
          <p><strong>Description:</strong> {job.jobDescription}</p>
          <p><strong>Gender:</strong> {job.gender}</p>
          <p><strong>Perks:</strong> {job.perks}</p>
          <p><strong>Working Days:</strong> {job.workingDays}</p>
          <p><strong>Shift:</strong> {job.shift}</p>
          <p><strong>Education Qualification:</strong> {job.educationQualification}</p>
          <p><strong>Experience:</strong> {job.experience}</p>
          <p><strong>Notice Period:</strong> {job.noticePeriod}</p>
          <p><strong>Salary:</strong> {job.salary}</p>
          
        </div>
      )} */}
    </div>
    );
};

export default JobCard1;