export const setSessionId = (sessionId)=>({
    type : 'SET_SESSION_ID',
    payload : sessionId
})
export const setUserId = (setUserId)=>({
    type : 'SET_USER_ID',
    payload : setUserId
})
export const clearSessionId = () => ({
    type: 'CLEAR_SESSION_ID',
});
