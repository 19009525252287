import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React from 'react';
import './style.css';

const referrals = [
  {
    name: 'John Doe',
    phone: '123-456-7890',
    city: 'New York',
    relationship: 'Friend',
    status: 'Success'
  },
  {
    name: 'Jane Smith',
    phone: '098-765-4321',
    city: 'Los Angeles',
    relationship: 'Colleague',
    status: 'In Progress'
  },
  {
    name: 'Bob Johnson',
    phone: '111-222-3333',
    city: 'Chicago',
    relationship: 'Relative',
    status: 'Rejected'
  },
  {
    name: 'John Doe',
    phone: '123-456-7890',
    city: 'New York',
    relationship: 'Friend',
    status: 'Success'
  },
  {
    name: 'Jane Smith',
    phone: '098-765-4321',
    city: 'Los Angeles',
    relationship: 'Colleague',
    status: 'In Progress'
  },
  {
    name: 'Bob Johnson',
    phone: '111-222-3333',
    city: 'Chicago',
    relationship: 'Relative',
    status: 'Rejected'
  },
  {
    name: 'John Doe',
    phone: '123-456-7890',
    city: 'New York',
    relationship: 'Friend',
    status: 'Success'
  },
  {
    name: 'Jane Smith',
    phone: '098-765-4321',
    city: 'Los Angeles',
    relationship: 'Colleague',
    status: 'In Progress'
  },
  {
    name: 'Bob Johnson',
    phone: '111-222-3333',
    city: 'Chicago',
    relationship: 'Relative',
    status: 'Rejected'
  }
];


const MyReferrals1 = () => {
    return (
      <>
      <DashboardNavbar />
      <div className="flex justify-center min-h-screen p-4 m-5 myreferral" >
    <div className="overflow-x-auto w-full md:w-4/5 lg:w-4/5">
      <h1 className="w-full text-2xl font-bold text-center text-white py-5">My Referrals 2</h1>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="px-4 py-2 text-left border-b-2 border-gray-300">S.no</th>
            <th className="px-4 py-2 text-left border-b-2 border-gray-300">Name</th>
            <th className="px-4 py-2 text-left border-b-2 border-gray-300">Phone No</th>
            <th className="px-4 py-2 text-left border-b-2 border-gray-300">City</th>
            <th className="px-4 py-2 text-left border-b-2 border-gray-300">Relationship</th>
            <th className="px-4 py-2 text-left border-b-2 border-gray-300">Status</th>
          </tr>
        </thead>
        <tbody>
          {referrals.map((referral, index) => (
            <tr key={index} className="hover:bg-gray-100">
              <td className="px-4 py-2 border-b border-gray-200 text-center">{index + 1}</td>
              <td className="px-4 py-2 border-b border-gray-200">{referral.name}</td>
              <td className="px-4 py-2 border-b border-gray-200">{referral.phone}</td>
              <td className="px-4 py-2 border-b border-gray-200">{referral.city}</td>
              <td className="px-4 py-2 border-b border-gray-200">{referral.relationship}</td>
              <td
                className={`px-4 py-2 border-b font-bold border-gray-200 ${
                  referral.status === 'Success' ? 'text-green-500' :
                  referral.status === 'In Progress' ? 'text-yellow-500' :
                  referral.status === 'Rejected' ? 'text-red-500' : ''
                }`}
              >
                {referral.status}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
        </>
      );
    }
        

export default MyReferrals1;
